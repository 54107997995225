import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Grommet, Heading, Image, Box } from "grommet"
import MaxWidth from "../components/Common/MaxWidth"
import SEO from "../components/seo"

import "./styles.css"

const notfound = require("../resources/notfound.jpeg")

const myTheme = {
  global: {
    font: {
      family: "Roboto",
    },
    colors: {
      brand: "#02203B",
      focus: "dark-6",
      text: {
        dark: "brand",
      },
    },
  },
  heading: {
    weight: 400,
    font: {
      family: "PT Serif",
    },
  },
  carousel: {
    animation: {
      duration: 500,
    },
    icons: {
      color: "brand",
      border: {
        size: 0,
        color: "none",
      },
    },
    disabled: {
      icons: {
        color: "grey",
      },
    },
  },
  button: {
    active: {
      border: {
        color: "#666666",
      },
    },
  },
}

const NotFoundPage = () => {
  return (
    <Grommet theme={myTheme}>
      <SEO title="404: Not found" />
      <Box background="#f6f9fc" height="100vh">
        <MaxWidth>
          <Image fit="contain" src={notfound} />
          <Heading level={3} size="large" alignSelf="center" textAlign="center" style={{maxWidth: "100%"}} responsive={false}>
            What are you doing? You're not suppose to be back here.
          </Heading>
          <Link to="/" style={{alignSelf: "center"}}>
            <Heading level={3}>Go Back</Heading>
          </Link>
        </MaxWidth>
      </Box>
    </Grommet>
  )
}

export default NotFoundPage
